// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
//import bsCustomFileInput from 'bs-custom-file-input'
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function updateAllStateIcons() {
  $('span.sync-state').each(function(index, element) {
    updateSingleSyncState($(element).data("statusResource"))
  })
}

function updateSingleSyncState(statusResource) {
  $.ajax({
    url: statusResource,
    type: "GET",
    success: function(data) {
      $("span[data-status-resource='" + statusResource + "']").children(".sync-state").hide()
      $("span[data-status-resource='" + statusResource + "']").children("#sync-state-" + data['sync_state']).show()
    }
  })
}

function toggleSync(statusResource, toggleResource) {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  $.ajax({
    url: toggleResource,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf
    },
    type: "PUT",
    success: function(data) {
      updateSingleSyncState(statusResource)
    }
  });
}

$(document).on('turbolinks:load', function() {
  updateAllStateIcons()
  $('span.sync-state').click(function(event) {
    toggleSync($(event.currentTarget).data('status-resource'), $(event.currentTarget).data('toggle-resource'))
  })
  //bsCustomFileInput.init()

  $('[data-toggle="tooltip"]').tooltip();  
})
